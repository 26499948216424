import React from 'react';
import styled from 'styled-components';
import { ModalBody, ModalFooter, Table } from 'reactstrap';
import { format } from 'date-fns';
import { formatCurrency } from 'utils/format';

import Button from 'components/Common/Form/Button';
import Modal from 'components/Common/Layout/Modal';

const StyledModal = styled(Modal)`
  max-width: 790px;

  .modal-content {
    padding: 32px 40px;
    .modal-header {
      .modal-title {
        margin-bottom: 0;
      }
    }
    .modal-body {
      padding: 20px;
      padding-bottom: 6px;
    }
  }
  .modal-footer {
    button {
      margin-top: 38px;
    }
  }
`;

const Note = styled.div`
  ${props => props.theme.main.fonts.application.micro};
`;

const StyledTable = styled(Table)`
  margin-bottom: 0;
  thead {
    tr {
      th {
        ${props => props.theme.main.fonts.body.bold};
        border-bottom: 2px solid ${props => props.theme.main.colors.neutral.line};
        border-top: none;
        padding-top: 24px;
        padding-bottom: 10px;
        &:first-of-type {
          padding-left: 0;
        }
        &:last-of-type {
          padding-right: 0;
        }
      }
    }
  }
  tbody {
    tr {
      td {
        ${props => props.theme.main.fonts.body.normal};
        border-top: 1px solid ${props => props.theme.main.colors.neutral.line};
        padding-top: 16px;
        padding-bottom:16px;
        &:first-of-type {
          padding-left: 0;
        }
        &:last-of-type {
          padding-right: 0;
        }
        &:nth-of-type(2) {
          ${props => props.theme.main.fonts.body.bold};
        }
      }
      &:last-of-type {
        td {
          border-bottom: 1px solid ${props => props.theme.main.colors.neutral.line};
        }
      }
    }
  }
`;

export default ({ isOpen=false, toggle, repaymentSchedule}) => {
  return (
    <StyledModal isOpen={isOpen} toggle={toggle}>
      <div className="modal-header">
        <h5 className="modal-title">
          Repayment schedule
        </h5>
      </div>
      <ModalBody>  
        <Note><b>Please note:</b> Your schedule is based on the payment frequency and loan length that you selected. If you choose to make additional payments, you can pay off your loan sooner.</Note> 
        <StyledTable>
          <thead>
            <tr>
              <th>Payment date</th>
              <th>Total Due</th>
              <th>Principle</th>
              <th>Interest</th>
              <th>Remaining</th>
            </tr>
          </thead>
          <tbody>
            {repaymentSchedule.map((schedule, i) => 
              <tr key={i}>
                <td>{schedule.paymentDate ?  format(new Date(schedule.paymentDate), 'LLL d yyyy') : ''}</td>
                <td>{formatCurrency(schedule.totalDue)}</td>
                <td>{formatCurrency(schedule.principle)}</td>
                <td>{formatCurrency(schedule.interest)}</td>
                <td>{formatCurrency(schedule.remaining)}</td>
              </tr>
            )}
          </tbody>
        </StyledTable>    
      </ModalBody>
      <ModalFooter>        
        <Button onClick={toggle} name="button-done">Done</Button>
      </ModalFooter>
    </StyledModal>);
}