import React from 'react';
import PropTypes from 'prop-types';
import styled  from 'styled-components';
import { FormGroup, Label, Col, Input } from 'reactstrap';
import breakpoint from 'styled-components-breakpoint';

import Footnote from 'components/Common/Layout/Footnote';

const StyledLabel = styled(Label)`
  ${props => props.theme.main.fonts.body.bold};
  color: ${props => props.theme.main.colors.grey.base};
  padding-bottom: 0;
  padding-top: 8px;
  ${breakpoint('md')` 
    padding-top: 0px;
  `}
`;

const StyledInput = styled(Input)`
  ${props => props.theme.main.fonts.body.normal};
  padding: 0;

  &:focus {
    outline: none;
  }
`;

const StyledFormGroup = styled(FormGroup)`
  margin-bottom: 0;
  ${breakpoint('md')` 
    padding-top: 8px;
  `}
`;

const StaticFieldRow = ({label, value = '', name, footnoteText = null, footnoteTo = null}) => (
  <StyledFormGroup row>
    <StyledLabel md={6}>{label} {footnoteText ? <Footnote to={footnoteTo}>{footnoteText}</Footnote> : null}</StyledLabel>
    <Col md={6} className="">
      <StyledInput data-testid={name} plaintext value={value} readOnly tabIndex="-1" />
    </Col>
  </StyledFormGroup>
);

StaticFieldRow.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  value: PropTypes.string,
  name: PropTypes.string
}

export default StaticFieldRow;