import React from 'react';
import PropTypes from 'prop-types';
import styled, { css }  from 'styled-components';
import { Alert as BootstrapAlert } from 'reactstrap';
import Check from 'images/check.svg';
import Exclamation from 'images/exclamation.svg';
import Warning from 'images/warning.white.svg';
import Info from 'images/info.svg';


const StyledAlert = styled(BootstrapAlert)`
  background-color: white;
  border-radius: 0;
  border: none;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);

  ${props => props.type === 'success' && css`
    border-top: 2px solid ${props => props.theme.main.colors.validation.success};
  `}
  ${props => props.type === 'warning' && css`
    border-top: 2px solid ${props => props.theme.main.colors.validation.warning};
  `}
  ${props => props.type === 'error' && css`
    border-top: 2px solid ${props => props.theme.main.colors.validation.error};
  `}
  ${props => props.type === 'info' && css`
    border-top: 2px solid ${props => props.theme.main.colors.secondary.base};
  `}

  padding: 24px;
  padding-top: 26px;
  margin-bottom: 32px;
  min-height: 85px;

  p {
    ${props => props.theme.main.fonts.body.normal};
    color: black;
    padding-left: 48px;
    margin-bottom: 0;
  }
`;

const Icon = styled.div`
  position: absolute;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-size: 60% 50%;
  background-repeat: no-repeat;
  background-position: center;
  ${props => props.type === 'success' && css`
    background-color: ${props => props.theme.main.colors.validation.success};
    background-image: url(${Check}); 
  `}
  ${props => props.type === 'warning' && css`
    background-color: ${props => props.theme.main.colors.validation.warning};
    background-image: url(${Exclamation}); 
  `}
  ${props => props.type === 'error' && css`
    background-size: 70% 75%;
    background-color: ${props => props.theme.main.colors.validation.error};
    background-image: url(${Warning}); 
  `}
  ${props => props.type === 'info' && css`    
    background-size: 68% 70%;
    background-color: ${props => props.theme.main.colors.secondary.base};
    background-image: url(${Info}); 
  `}
`;

const Alert = ({children, type = 'success', className = null}) => (
  <StyledAlert type={type} className={className}>
    <Icon type={type} /> 
    <p data-testid="alert">{children}</p>
  </StyledAlert>);

Alert.propTypes = {
  children: PropTypes.node.isRequired,
  type: PropTypes.oneOf(['success', 'warning', 'error', 'info'])
}

export default Alert;