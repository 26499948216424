import React from 'react';
import styled from 'styled-components';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import breakpoint from 'styled-components-breakpoint';

const StyledModal = styled(Modal)`
  max-width: 700px;
  padding-left: 24px;
  padding-right: 24px;
  margin-left: 0;
  margin-right: 0;
  ${breakpoint('sm')` 
    margin-left: auto;
    margin-right: auto;
  `}
  .modal-content {
    border-radius: 0;
    border: none;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
    height: calc(100vh - 56px);
    min-height: 400px;
    padding: 32px 24px;
    .modal-header {
      flex-direction: column;
      border-bottom: none;
      padding:0;
      padding-bottom: 8px;
      .modal-title {
        ${props => props.theme.main.fonts.application.h4};
        margin-bottom: 8px;
      }
      p {
        ${props => props.theme.main.fonts.micro.normal};
        margin-bottom: 0;
      }
      ${breakpoint('md')` 
        padding-bottom: 24px;
        .modal-title {        
          margin-bottom: 16px;
        }
      `}
    }
    .modal-body {
      overflow:auto;
      background-color: ${props => props.theme.main.colors.neutral.offwhite};
      height: 100%;
    }
    .modal-footer {
      border-top: none;
      padding:0;
      display: flex;
      flex-direction: column;
      .btn-secondary {
        margin-left: 0;
      }
    }
  }
`;

export default ({ children, isOpen=false, toggle = () => {}, className = null }) => {
  return (
    <StyledModal isOpen={isOpen} toggle={toggle} className={className}>
      {children}
    </StyledModal>);
}