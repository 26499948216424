import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { formatCurrency, formatPercent } from 'utils/format';
import { format } from 'date-fns';
import styled, { css } from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import { Row, Col } from 'reactstrap';

import Form from 'components/Common/Form/Form';
import Header from 'components/Common/Layout/Header';
import Box from 'components/Common/Layout/Box';
import Alert from 'components/Common/Layout/Alert';
import Navigation from 'components/Common/Layout/Navigation';
import StaticFieldRow from 'components/Common/Form/StaticFieldRow';
import Disclaimer from 'components/Common/Layout/Disclaimer';
import RepaymentSchedule from './RepaymentSchedule';

const LoanAmount = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-bottom: 2px solid ${props => props.theme.main.colors.secondary.base};
  padding-bottom: 24px;
  .heading {
    ${props => props.theme.main.fonts.body.bold};
    color: ${props => props.theme.main.colors.secondary.dark1};
  }
  .amount {
    ${props => props.theme.main.fonts.application.shoutout};
    font-size: 48px;
    line-height: 58px;
    color: ${props => props.theme.main.colors.secondary.dark1};
    span {
      font-size: 24px;
      line-height: 28px;
    }
  }
`;

const Heading = styled.div`
  ${props => props.theme.main.fonts.body.bold};
  color: ${props => props.theme.main.colors.grey.base};
`;

const Amount = styled.div`
  ${props => props.theme.main.fonts.application.h2};
  color: black;
  span {
    font-size: 18px;
    line-height: 24px;
  }
`;

const MonthlyPayments = styled(Col)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  
  padding-top: 24px;
  padding-bottom: 24px;

  ${breakpoint('md')` 
    border-right: 2px solid ${props => props.theme.main.colors.neutral.line};
  `}
`;

const Interest = styled(Col)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-left: 24px;
  padding-bottom: 24px;
  ${breakpoint('md')` 
    padding-bottom: 0;
  `}
`;

const StyledRow = styled(Row)`
  border-bottom: 2px solid ${props => props.theme.main.colors.neutral.line};
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 16px;
`;

const Paragraph = styled.p`
  &&& {
    color: ${props => props.theme.main.colors.grey.base};
    margin-bottom: 24px;

    ${props => props.color === 'black' && css`
      color: black;
    `}

    a {
      color: ${props => props.theme.main.colors.secondary.dark1};
      &:hover {
        text-decoration: none;
      }
    }
  }
`;

const Link = styled.div`
  display: inline-block;
  ${props => props.theme.main.fonts.body.normal};
  color: ${props => props.theme.main.colors.primary.base};
  text-align: center;
  margin-top: 24px;
  padding: 8px 16px;
  cursor: pointer;
  border: 1px solid ${props => props.theme.main.colors.primary.base};
`;

const StyledAlert = styled(Alert)`
  margin-bottom: 24px;
`;

const StyledBox = styled(Box)`
  text-align: center;
  .form-group {
    text-align: center;
    input {
      text-align: center;
    }
    ${breakpoint('md')` 
      text-align: left;
      input {
        text-align: left;
      }
    `}
  }
`;

const StyledHeader = styled(Header)`
  font-size: 32px;
  line-height: 38px;
  ${breakpoint('md')` 
    font-size: 48px;
    line-height: 58px;
  `}
`;

export default React.memo(() => {
  const { incomeAccountId, relationships, timeout, isMobile } = useSelector(state => state.reducer);
  const loanSummary = useSelector(state => state.reducer.loanSummary);
  const [displayLoanSchedule, setDisplayLoanSchedule] = useState(false);

  let paymentFrequencyLabel = '-'

  if(loanSummary.frequency === 'BiWeekly') {
    paymentFrequencyLabel = 'Bi-weekly';
  }
  else if(loanSummary.frequency) {
    paymentFrequencyLabel = loanSummary.frequency;
  }

  const goToOnlineBanking = (e) => {
    e.preventDefault();
    if(isMobile) {
      window.location.replace('/logout#closeMobile');
    }
    else {
      window.location.replace(`${process.env.GATSBY_VANCITY_URL}/OnlineBanking/MyAccounts/`);
    }
  }

  const relationship = relationships.find(relationship => relationship.accounts.find(account => account.account.id.value === incomeAccountId));
  const account = relationship ? relationship.accounts.find(account => account.account.id.value === incomeAccountId).account : null;

  return (
    <Form>
      <StyledHeader>Your loan has been successfully funded</StyledHeader>
      {timeout ? 
        <div>
          <StyledAlert type="info">
            Your session has timed out due to inactivity. You may now close this window.
          </StyledAlert>
          <Paragraph color="black">
            Your Loan Disclosure Statement and Terms & Conditions will be available under your e-Documents, in online banking. 
          </Paragraph>
        </div>
      :
        <div>
          <Paragraph>Your loan has been successfully funded. Please note, it can take <b>up to 10 minutes</b> to show in your account.</Paragraph>
          <StyledBox>
            <Row>
              <Col>
                <LoanAmount>
                  <div className="heading">Loan amount</div>
                  <div className="amount" data-testid="loanAmount">{loanSummary.amount ? <div>${loanSummary.amount}</div> : '-'}</div>
                </LoanAmount>
              </Col>
            </Row>
            <StyledRow>
              <MonthlyPayments md={6}>
                <Heading>{paymentFrequencyLabel} payment</Heading>
                <Amount data-testid="paymentAmount">{loanSummary.paymentAmount ? <div>${loanSummary.paymentAmount.toFixed(2)}</div> : '-'}</Amount> 
              </MonthlyPayments>
              <Interest md={6}>
                <Heading>Loan length</Heading>
                <Amount data-testid="loanLength">{loanSummary.term} {loanSummary.termUnit ? loanSummary.termUnit.toLowerCase() : ''}</Amount> 
              </Interest>
            </StyledRow>
            <StaticFieldRow label={<>Interest <span data-testid="interestRate">{loanSummary.interestRate ? formatPercent(loanSummary.interestRate) : `-`}</span></>} value={formatCurrency(loanSummary.totalCostOfCredit)} name="totalCostOfCredit" footnoteText="1" footnoteTo="footnote-1" />
            <StaticFieldRow label="Loan + interest" value={formatCurrency(loanSummary.amount + loanSummary.totalCostOfCredit)} name="totalCostOfCredit" />
            <StaticFieldRow label="Start date" value={loanSummary.startDate ? format(new Date(loanSummary.startDate), 'MMMM d yyyy') : ''} name="startDate" />
            <StaticFieldRow label="First payment" value={loanSummary.firstPaymentDate ? format(new Date(loanSummary.firstPaymentDate), 'EEEE, MMMM d yyyy') : ''} name="firstPayment" footnoteText="2" footnoteTo="footnote-2" />
            <StaticFieldRow label="Last payment" value={loanSummary.lastPaymentDate ?  format(new Date(loanSummary.lastPaymentDate), 'EEEE, MMMM d yyyy') : ''} name="lastPayment" />
            <StaticFieldRow label="Deposited into" value={`${loanSummary.fundedAccountTitle ? loanSummary.fundedAccountTitle : ''} ${loanSummary.fundedAccountNumber ? loanSummary.fundedAccountNumber: ''}`} name="fundedAccount" />
            <RepaymentSchedule isOpen={displayLoanSchedule} toggle={() => setDisplayLoanSchedule(false)} repaymentSchedule={loanSummary.repaymentSchedule || []} />
            <Link onClick={() => setDisplayLoanSchedule(true)} data-testid="button-repaymentSchedule">View repayment schedule</Link>
          </StyledBox>
          {loanSummary.isDocumentArchived ? 
            <Paragraph color="black">Your Loan Disclosure Statement and Terms & Conditions will be available under {isMobile ? 'e-Documents' : <a href={`${process.env.GATSBY_VANCITY_URL}/OnlineBanking/MyAccounts/Documents/`} target="_blank">e-Documents</a>}, inside online banking.</Paragraph> : 
            <Paragraph color="black">Your Loan Disclosure Statement and Terms & Conditions is not available in your {isMobile ? 'e-Documents' : <a href={`${process.env.GATSBY_VANCITY_URL}/OnlineBanking/MyAccounts/Documents/`} target="_blank">e-Documents</a>} in Online Banking. Please call <a href="tel:18888262489">1-888-826-2489</a> to obtain this document.</Paragraph>        
          }      
          { loanSummary.newRelationship ?
            <Alert type="info">
              Loan payments will be automatically withdrawn from {account ? account.product.name : ''} <span data-testid="incomeAccountId">{incomeAccountId}</span>. A new relationship (<span data-testid="newRelationship">{loanSummary.newRelationship}</span>) was created for your loan which will appear in your online banking. 
            </Alert> :
            <Alert type="info">
              Loan payments will be automatically withdrawn from {account ? account.product.name : ''} <span data-testid="incomeAccountId">{incomeAccountId}</span>.
            </Alert>
            }
          <Navigation buttonLabel="Exit to online banking" onClickForward={goToOnlineBanking} />
          <Disclaimer>
            <p id="footnote-1"><sup>1</sup> The annual percentage rate (APR) is compounded monthly, not in advance. APR assumes no fees or charges apply. If additional fees and/or charges apply, the total Cost of Credit and APR would increase. This product, its features and advertised interest rates may change at any time and may be withdrawn by Vancity at any time for any reason without prior notice to the member.</p>
            <p id="footnote-2"><sup>2</sup> Dates are calculated based on Pacific Standard Time (PST).</p>
          </Disclaimer>
        </div> }
    </Form>);
});
